<template lang="pug">
page-wrapper(:title="$t('codeInsertV2.titles.connect')")
  template(slot="content")
    .d-flex.flex-column.domain-form
      om-body-text.mb-1(bt400lglg) {{ $t('codeInsertV2.selectDomain') }}
      om-select#codeInsertSelect(
        v-if="showSelectInput"
        :options="selectOptions"
        :disabled="loading"
        v-model="selected"
        optionText="domain"
        optionKey="key"
        searchable
        @input="handleDomainChange"
      )
      om-input#newDomain.form-group(
        v-else
        type="url"
        v-model="newDomain"
        :disabled="loading"
        large
        @input="resetSelectedIndex"
        @enter="verifyDomain"
        placeholder="mywebsite.com"
      )
        template(slot="error" v-if="hasError")
          span {{ $t('invalidUrl.default') }}
      .d-flex.w-100.justify-content-between
        om-link(primary medium @click="navigateToDomains") {{ $t('addNewDomain') }}
        om-button(primary :disabled="!isDomainSelected" :loading="loading" @click="verifyDomain") {{ $t('onboarding.nextStep') }}
      om-body-text(bt400sm v-if="loading") {{ $t('codeInsertV2.domainCheckInProgress') }}
  template(slot="image")
    om-monks.ml-auto.w-100.domain-monk(monk="hi-user")
</template>

<script>
  import { mapGetters } from 'vuex';
  import GET_DOMAIN_INFO from '@/graphql/GetDomainInfo.gql';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import { apolloClient } from '@/apollo';
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import { codeInsertPlatforms } from '@/router/routes/codeinsert';
  import { PageWrapper } from '@/components/CodeInsert';
  import {
    isDateOlderThanADay,
    validateDomain,
    removeProtocolFromDomain,
    purifyDomain,
  } from '@/util';
  import urlModsMixin from '@/mixins/urlMods';
  import { ONLY_GUIDE_SCREEN } from './constants';

  export default {
    name: 'Domain',
    components: { PageWrapper },
    mixins: [childRouteMixin, urlModsMixin],
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.disableAutoRedirect = (from.name || '').includes('code-insert');
      });
    },
    data: () => ({
      newDomain: null,
      loading: null,
      selected: null,
      disableAutoRedirect: false,
    }),
    validations: {
      newDomain: {
        url(v) {
          return validateDomain(v, false);
        },
      },
    },
    computed: {
      ...mapGetters(['domains', 'isNewSiteDetectionEnabled']),
      selectOptions() {
        return (this.domains || [])
          .map(({ domain }) => ({
            key: domain,
            domain,
          }))
          .sort((a, b) => (a.domain > b.domain ? 1 : -1));
      },
      showSelectInput() {
        return this.domains?.length;
      },
      isDomainSelected() {
        return this.selected || this.newDomain;
      },
      hasError() {
        return (
          this.$v.newDomain.$dirty &&
          (!this.newDomain || (this.$v.newDomain.$error && !this.$v.newDomain.url))
        );
      },
    },
    created() {
      const currentURL = new URL(window.location.href);
      const queryDomain = purifyDomain(currentURL.searchParams.get('domain') || null);
      const domains = this.domains || [];
      let domain;
      if (domains.length === 1) {
        domain = domains[0].domain;
      } else if (queryDomain && domains.find((domain) => queryDomain === domain.domain)) {
        domain = queryDomain;
      }

      if (domain) {
        this.selected = { key: domain, domain };
      }
    },
    async mounted() {
      if (!this.disableAutoRedirect && this.selected) {
        await this.verifyDomain();
      }
    },
    methods: {
      handleDomainChange(value) {
        this.setQueryStringParameter('domain', value?.domain);
      },
      navigateToDomains() {
        this.$router.push({ name: 'domains' });
      },
      resetSelectedIndex() {
        this.selected = null;
      },
      validateInputs() {
        if (this.selected) {
          return true;
        }
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      async saveNewDomain(domain) {
        try {
          await apolloClient.query({
            query: ADD_DOMAIN,
            variables: { domain },
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async getPlatformEngine(domain) {
        try {
          const {
            data: {
              siteInfo: { engine, inserted },
            },
          } = await apolloClient.query({
            query: GET_DOMAIN_INFO,
            variables: {
              domain,
              improved: this.isNewSiteDetectionEnabled ?? false,
            },
          });
          if (inserted) {
            this.$notify({
              type: 'success',
              text: this.$t('codeInsertV2.alreadyConnected'),
            });
          }
          return this.correctPlatform(engine);
        } catch (_) {
          return false;
        }
      },
      getPlatformScreen(platform) {
        if (!platform || platform === 'custom') {
          return 'manual';
        }
        return ONLY_GUIDE_SCREEN.includes(platform) ? 'platform-setup' : 'platform';
      },
      navigateToPlatformPage(domain, engine) {
        const platform = this.getPlatform(engine);
        const page = this.getPlatformScreen(platform);
        const name = this.getRouteName(page);
        this.$router.push({
          name,
          params: { platform },
          query: { ...this.$route.query, domain },
        });
      },
      getRouteName(page = 'platform') {
        // returns the page name based on the page param.
        return this.$route.name.replace('domain', page);
      },
      getPlatform(engine) {
        // checks that the platform is supported
        return codeInsertPlatforms.find((platform) => engine === platform) || '';
      },
      async verifyDomain() {
        if (this.loading || !this.isDomainSelected || !this.validateInputs()) {
          return;
        }

        this.loading = true;

        if (this.newDomain) {
          this.newDomain = removeProtocolFromDomain(this.newDomain);
          const saved = await this.saveNewDomain(this.newDomain);
          if (!saved) {
            this.loading = false;
            return;
          }

          const engine = await this.getPlatformEngine(this.newDomain);

          this.loading = false;
          this.navigateToPlatformPage(this.newDomain, engine);
        } else if (this.selected) {
          const { platform, domain, lastRequestDate } = this.getDomainBasedOnTheSelected();
          let engine;
          if (lastRequestDate && !isDateOlderThanADay(lastRequestDate, 1)) {
            engine = this.correctPlatform(platform);
          } else {
            engine = await this.getPlatformEngine(domain);
          }
          this.loading = false;
          this.navigateToPlatformPage(domain, engine);
        }
      },
      getDomainBasedOnTheSelected() {
        return this.domains.find(({ domain }) => domain === this.selected.key);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/helpers/media-breakpoint"
  .domain
    &-form
      width: min(450px, 100%)
      row-gap: 1rem
      @include media-breakpoint-down(sm)
        width: 100%
        margin: auto
      .radio-select-card-header
        padding-bottom: 0
    &-monk
      margin-top: 4rem
      max-width: 17.5rem
      @include media-breakpoint-down(md)
        margin-top: 1.5rem
</style>
